/* Copyright (C) Okahu Inc 2023-2024. All rights reserved.  */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
    --okh-radius: 1rem;

    /* okahu */
    /* primary */
    --ocean: 197, 62%, 38%;
    --jade: 174, 57%, 47%;
    /* secondary */
    --sand: 38, 100%, 97%;
    --mustard: 37, 100%, 74%;
    --poppy: 37, 100%, 74%;
    /* tertiary */
    --yellow-light: 47, 89%, 93%, 1;
    --yellow-dark: 54, 87%, 53%, 1;
    --red-light: 356, 88%, 97%, 1;
    --red-dark: 355, 91%, 46%, 1;
    --purple-light: 280, 60%, 97%, 1;
    --purple-dark: 287, 57%, 41%, 1;
    --poppy-light: 356, 100%, 95%;
    --poppy-dark: 356, 84%, 69%;
    --blue-light: 216, 100%, 97%, 1;
    --blue-dark: 215, 99%, 43%, 1;
    --green-light: 88, 100%, 97%, 1;
    --green-dark: 88, 100%, 32%, 1;
    /* utils */
    --gray-00: 200, 18%, 93%;
    --gray-01: 206, 13%, 79%;
    --gray-02: 189, 10%, 59%;
    --gray-03: 190, 17%, 30%;
    --gray-04: 190, 71%, 14%;
    --gray-05: 196, 71%, 13%;
    --gray-06: 197, 71%, 11%;

    --link-active: var(--jade);
    --link-visited: var(--ocean);
    --nav-link-active: var(--ocean);
    --form-input-disabled: var(--gray-02);
    --nav-selected-state: var(--jade);
    --line-primary: var(--gray-02);
    --graph: 0 0% 100%;
    --border-form-control-hover: var(--ocean);
    --fill-button-rest: var(--jade);
    --fill-button-focus: var(--ocean);
    --fill-button-hover: var(--ocean);
    --fill-form-control-disabled: var(--gray-01);
    --secondary-background: var(--gray-00);
    --input-fill: 0 0% 100%;
    --graph-edge-focus: var(--jade);
    --info-light: var(--blue-light);
    --info-dark: var(--blue-dark);
    --recommendation-light: var(--purple-light);
    --recommendation-dark: var(--purple-dark);
    --warning-light: var(--sand);
    --warning-dark: var(--mustard);
    --icon-fill: var(--ocean);
    --error-light: var(--poppy-light);
    --error-dark: var(--poppy-dark);
    --notification-error-light: 356, 100%, 95%;
    --notification-error-dark: 356, 84%, 69%;
    --notification-success-dark: var(--jade);

    --body-primary: var(--gray-05);
    --body-secondary: var(--gray-03);
    --body-subtle: var(--gray-02);
    --form-input-rest: var(--gray-05);
    --canvas-background-primary: 0 0% 100%;
    --canvas-background-secondary: var(--sand);
    --card-00: var(--gray-00);
    --card-01: var(--gray-01);
    --card-02: var(--gray-02);
    --line-secondary: var(--gray-01);
    --line-tertiary: var(--gray-00);
    --nav-background: var(--sand);
    --border-form-control-rest: var(--gray-01);
    --border-form-control-focus: var(--ocean);
    --border-form-control-disabled: var(--gray-00);
    --graph-node-background: var(--gray-00);
    --graph-edge-rest: var(--gray-01);
    --required-field: var(--warning-dark);
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    /* okahu */
    --body-primary: 0 0% 100%;
    --body-secondary: var(--gray-01);
    --body-subtle: var(--gray-01);
    --form-input-rest: var(--gray-00);
    --canvas-background-primary: var(--gray-06);
    --canvas-background-secondary: var(--gray-05);
    --card-00: var(--gray-06);
    --card-01: var(--gray-05);
    --card-02: var(--gray-04);
    --line-secondary: var(--gray-03);
    --line-tertiary: var(--gray-04);
    --nav-background: var(--gray-07);
    --border-form-control-rest: var(--gray-02);
    --border-form-control-focus: var(--jade);
    --border-form-control-disabled: var(--gray-03);
    --graph-node-background: var(--gray-07);
    --graph-edge-rest: var(--gray-03);
    --required-field: var(--warning-light);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* The following CSS class acts as your embedded license for and must be present on the pages where you intend to deploy GoJS apps. The CSS must affect the page as a top-level rule and cannot be nested. Be sure that the class is exempted from CSS minification tools such as PurgeCSS or cssnano. */

.gshDisplay {
  border-color: #c6aebe;
  opacity: 4%;
  margin-bottom: 17px;
}

@keyframes pulse-custom {
  0%,
  100% {
    opacity: 0.24;
  }
  50% {
    opacity: 0.48;
  }
}

.animate-pulse-custom {
  animation: pulse-custom 2s linear infinite;
}

.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-input {
  -moz-appearance: textfield;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  background-color: transparent;
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 0.25rem;
}
